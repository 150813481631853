import React, { FC, memo } from "react";
import { Modal, Image } from "antd";
import { useSelector } from "react-redux";
import { getSasToken } from "../../../../../../stores";
import { contactChannels } from "../QuotationApprovalModal";;
import { CkModal } from "../../../../../../CkUI";
import "./styles.css";

interface IProps {
  open: boolean;
  setOpen: Function;
  method: string;
  comments: string;
  confirmationImage: string;
  appointmentTracking: any;
}

const QuotationInfoModal: FC<IProps> = ({
  open,
  setOpen,
  appointmentTracking
}) => {
  const sasToken: string = useSelector(getSasToken);

  const onCancel = () => {
    setOpen(false);
  };

  if (appointmentTracking === undefined) return null;
  if (appointmentTracking?.quotationStatus !== "APPROVED" || appointmentTracking?.quotationApprovedByWorkshop !== true) return null;

  return (
    <CkModal
      open={open}
      onCancel={onCancel}
      className="quotation-info-modal-container"
      title="Confirmación por otro medio"
      zIndex={5000}
    >
      <p className="description">
        Aprobaste esta cotización por tu cliente, ya que confirmaron por otro
        medio.
      </p>
      {appointmentTracking?.quotationUserContactedVia &&
        <div>
          <p className="confirmation-label">Medio de confirmación:</p>
          <p className="confirmation-used">{contactChannels.filter((option) => appointmentTracking?.quotationUserContactedVia === option.value)[0].label}</p>
        </div>
        }
      {appointmentTracking?.quotationComment &&
        <div>
          <p className="confirmation-label">Comentarios</p>
          <p className="description">{appointmentTracking?.quotationComment}</p>
        </div>}

      {appointmentTracking?.quotationApprovedByWorkshopSupport &&
        <div>
          <p className="confirmation-label">Captura de confirmación</p>
          <Image src={`${appointmentTracking?.quotationApprovedByWorkshopSupport}?${sasToken}`} />
        </div>}
        
    </CkModal>
  );
};

export default memo(QuotationInfoModal);
