import React from "react";
import { Col, Modal, Row, Button } from "antd";
import { CkModal } from "../../../CkUI";
import { CloseIcon } from "../../../assets/SvgIcons";

interface IProps {
  icon?: JSX.Element;
  title?: string;
  message?: string;
  subMessage?: string;
  onOk: any;
  onCancel: any;
  visible: boolean;
  okText?: string;
  cancelText?: string;
  onCloseIcon?: Function;
  zIndex?: number;
  type?: "delete" | "default";
}

export const ConfirmationModal: React.FC<IProps> = ({
  icon,
  title,
  message,
  subMessage,
  onOk,
  onCancel,
  visible,
  okText,
  cancelText,
  onCloseIcon,
  zIndex,
  type,
}: IProps) => {
  return (
    <CkModal
      //title={title ? title : null}
      open={visible}
      onCancel={onCloseIcon ? onCloseIcon : onCancel}
      maskClosable={false}
      destroyOnClose={true}
      zIndex={zIndex ? zIndex : 3000}
      type={type ? type : "default"}
      primaryAction={{
        label: okText ? okText : "Sí",
        onClick: onOk ? onOk : null,
      }}
      secondaryAction={{
        label: cancelText ? cancelText : "No",
        onClick: onCancel ? onCancel : null,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <>{icon}</>

        <h1 className="modal-title">
          {title ? title : null}
        </h1>

        <p
          style={{
            textAlign: "center",
            color: "var(--footer-background-dark)",
          }}
        >
          {message}
        </p>

        <p style={{ textAlign: "center", color: "var(--mariner-dark-color)" }}>
          {subMessage}
        </p>
      </div>
    </CkModal>
  );
};
