import React, { FC, memo, useState, useEffect } from "react";
import { Modal, Button, Input, Upload, Spin } from "antd";
import {
  RcFile,
  UploadProps as TUploadProps,
} from "antd/es/upload/interface";
import {
  PictureOutlined
} from "@ant-design/icons"
import moment from "moment";
import { BlobServiceClient } from "@azure/storage-blob";
import Compressor from "compressorjs";

import OptionsGrid from "./OptionsGrid";
import { TQuotationStatus } from "../";
import { ConfirmationModal } from "../../../ConfirmationModal";
import CustomAttachmentPreview from "./CustomAttachmentPreview";
import { CKCloseIcon, CalendarDeleteIcon } from "../../../../../../assets/SvgIcons";

import "./styles.css";
import { useSelector } from "react-redux";
import { getSasToken } from "../../../../../../stores";
import { CkModal, CkButton, CkMessage } from "../../../../../../CkUI";

interface IUploadAttachmentOutput {
  fileName: string;
  photoUrl: string;
}

type TUploadAttachmentOutput = IUploadAttachmentOutput | null;

const getBase64 = (file: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export interface ISelectedFile {
  uid: string;
  name: string;
  status: string;
  file?: RcFile;
  preview?: string;
  photoUrl?: string;
}

const { TextArea } = Input;

export type TContactChannels = "CALL" | "SMS" | "WHATSAPP" | "ONSITE" | "EMAIL" | "OTHER";

export const contactChannels: {
  label: string;
  value: TContactChannels
}[] = [
  {
    label: "Llamada",
    value: "CALL"
  },
  {
    label: "Mensaje SMS",
    value: "SMS"
  },
  {
    label: "WhatsApp",
    value: "WHATSAPP"
  },
  {
    label: "En persona",
    value: "ONSITE"
  },
  {
    label: "E-mail",
    value: "EMAIL"
  },
  {
    label: "Otro",
    value: "OTHER"
  }
];

interface IProps {
  showQuotationApprovalModal: boolean;
  setShowQuotationApprovalModal: (newValue: boolean) => void;
  updateQuotationStatus: (status: TQuotationStatus, approvalData?: {
    comment?: string;
    imageSupport?: string;
    contactedVia: TContactChannels;
  }) => void;
  appointmentId: any;
}
const QuotationApprovalModal: FC<IProps> = ({
  showQuotationApprovalModal,
  setShowQuotationApprovalModal,
  updateQuotationStatus,
  appointmentId
}) => {
  const sasToken = useSelector(getSasToken);
  const [comments, setComments] = useState<string>("");
  const [imageSupport, setImageSupport] = useState<ISelectedFile>();
  const [confirmApproval, setConfirmApproval] = useState<boolean>(false);
  const [userContactedVia, setUserContactedVia] = useState<TContactChannels>();
  const [containerClient, setContainerClient] = useState<any>();
  let blobService: any = null;
  const account = process.env.REACT_APP_BLOB_ACCOUNT as string;
  const containerName = "up-images-container";
  const [loadingImage, setLoadingImage] = useState<boolean>(false);

  useEffect(() => {
    if (sasToken) {
      blobService = new BlobServiceClient(`${account}?${sasToken}`);

      setContainerClient(blobService.getContainerClient(containerName));
    }
  }, [sasToken]);

  const onOk = () => {
    setConfirmApproval(true);
  };

  const onCancel = () => {
    setComments("");
    setUserContactedVia(undefined);
    setConfirmApproval(false);
    setShowQuotationApprovalModal(false);
  };

  const uploadFileAndCompress = async (
    file: RcFile
  ): Promise<TUploadAttachmentOutput> => {
    return new Promise(async (resolve, reject) => {
      let date = new Date();
      let fileName = `diagnosticForm/${appointmentId}/${date.getTime()}_${
        file.name
      }`;
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      try {
        new Compressor(file, {
          quality: 0.6,
          convertTypes:
            "image/png, image/webp, image/gif, image/tiff, image/apng, image/ico, image/cur, image/ai, image/svg, image/raw, image/jfif",
          convertSize: 3000000,
          async success(result) {
            await blockBlobClient.upload(result, result.size);
            let attached = {
              fileName: file.name,
              photoUrl: blockBlobClient.url.split("?")[0],
            };
            resolve(attached);
          },
        });
      } catch (error) {
        console.info("error compressing and uploading file", error);
        resolve(null);
      }
    });
  };

  const uploadProps: TUploadProps = {
    name: "file",
    showUploadList: false,
    multiple: false,
    accept: "image/*",
    beforeUpload: async (file: RcFile): Promise<any> => {
      const errorMessage =
        "Solo se soportan archivos png, jpg y jpeg con un tamaño menor a 20 MB.";
      const isLt20M = file.size / 1024 / 1024 < 20;

      if (!file.type.startsWith("image/")  || !isLt20M ) {
        CkMessage({type: "error", text: errorMessage});
        return;
      }

      setLoadingImage(true);

      const preview = await getBase64(file as RcFile);

      const uploadRequest = await uploadFileAndCompress(file as RcFile);
      if (uploadRequest === null) {
        CkMessage({type: "error", text: "Ocurrio un error al cargar el archivo."});
      } else {
        setImageSupport({
          uid: `${file.name}-${moment().unix()}`,
          name: file.name,
          status: "done",
          file: file,
          preview: preview,
          photoUrl: uploadRequest.photoUrl,
        });
        setLoadingImage(false);
      }
      
      
    },
  };

  return (
    <>
      <CkModal
        className="approval-modal-container"
        zIndex={5500}
        open={showQuotationApprovalModal}
        onOk={onOk}
        onCancel={onCancel}
        footer={null}
        closeIcon={<CKCloseIcon />}
        actionButtonsDirection={"column"}
        primaryAction={{
          label: "Confirmar",
          onClick: onOk,
          disabled: userContactedVia === undefined
        }}
        tertiaryAction={{
          label: "Cancelar",
          onClick: onCancel
        }}
        title="El cliente confirmó por otro medio"
      >

        <Spin size="large" spinning={loadingImage} />
        
        <p className="description">
          Por favor indica el medio por el que el cliente aprobó esta etapa de
          la cita, si es que no realizó la confirmación por medio de CarKer.
        </p>

        <OptionsGrid
          selectedOption={userContactedVia}
          setSelectedOption={setUserContactedVia}
        />

        <p className="description">
          Deja comentarios relevantes o adjunta una captura de la confirmación.
        </p>

        <div className="ipunts-container">
          <div className="text-area">
            <label htmlFor="comments" className="label">
              Comentarios
            </label>
            <TextArea
              rows={2}
              name="comments"
              maxLength={512}
              value={comments}
              onChange={(ev: any) => setComments(ev.target.value)}
              className="input"
            />
          </div>

          <div
            className="upload-container"
          >
            {imageSupport === undefined &&
              <Upload {...uploadProps}>
                <PictureOutlined />
                <p>Adjuntar captura de la confirmación</p>  
              </Upload>}
            {imageSupport !== undefined && (
              <CustomAttachmentPreview
                file={imageSupport}
                setFile={setImageSupport}
                readOnly={false}
              />
            )}
          </div>
        </div>
      </CkModal>
      <ConfirmationModal
        zIndex={5500}
        visible={confirmApproval}
        onOk={async () => {
          setConfirmApproval(false);
          updateQuotationStatus("APPROVED", {
            comment: comments,
            imageSupport: imageSupport?.photoUrl,
            contactedVia: userContactedVia!
          });
        }}
        onCancel={() => setConfirmApproval(false)}
        title="Advertencia"
        message="Una vez aprobada la cotización no podrá ser editada. ¿Desea proceder?"
        icon={<CalendarDeleteIcon />}
      />
    </>
  );
};

export default memo(QuotationApprovalModal);
