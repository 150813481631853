import React, {
  FC,
  memo,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { Carousel, Button, Checkbox, Input, InputNumber } from "antd";
import { DeleteOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
import { CarouselRef } from "antd/lib/carousel";
import {
  IQuotationLine,
  IFormLine,
  ITotal,
  ISubtotal,
  calculateCardSubtotals,
  calculateCardsTotals,
} from "../";
import { useIsMobileScreen } from "../../../../../Utilities";
import "./styles.css";

const { TextArea } = Input;

interface IProps {
  quotationItems: any[];
  setQuotationItems: (newValue: any[]) => void;
  isEditable: boolean;
}
const QuotationForms: FC<IProps> = ({
  quotationItems,
  setQuotationItems,
  isEditable,
}) => {
  /*
  +-----------------------------------------------------------+
  |                                                           |
  |                   States and Refs                         |
  |                                                           |
  |___________________________________________________________+
  */
  const isMobileScreen = useIsMobileScreen(1300);
  const carouselRef = useRef<CarouselRef>(null);
  const [totals, setTotals] = useState<ITotal>({
    totalDiscount: 0,
    totalToPay: 0,
  });
  const [subtotals, setSubtotals] = useState<ISubtotal[]>([]);
  const [activeCard, setActiveCard] = useState<number>(0);

  /*
  +-----------------------------------------------------------+
  |                                                           |
  |                       Functions                           |
  |                                                           |
  |___________________________________________________________+
  */

  const nextCard = () => {
    if (carouselRef.current && activeCard < quotationItems.length - 1) {
      carouselRef.current.goTo(activeCard + 1);
      setActiveCard(activeCard + 1);
    }
  };

  const prevCard = () => {
    if (carouselRef.current && activeCard > 0) {
      carouselRef.current && carouselRef.current.goTo(activeCard - 1);
      setActiveCard(activeCard - 1);
    }
  };

  const getActiveIndex = (): number => {
    if (carouselRef.current)
      return carouselRef.current.innerSlider.state.currentSlide;
    return -1;
  };

  const addLineToCard = useCallback(() => {
    const activeIndex = getActiveIndex();
    if (activeIndex < 0) return;
    const emptyLine = {
      id: 0,
      label: "",
      description: "", // Must be retrieved from backend
      pricePerUnit: 0, // Must be retrieved from backend
      numberOfUnits: 1,
      discount: 0,
      amountToDiscount: 0,
      lineTotal: 0, // calculate if price is provided on backend
    };
    const objectCopy = [...quotationItems];
    objectCopy[activeIndex].quotation.lines.push(emptyLine);
    setQuotationItems(objectCopy);
  }, [quotationItems]);

  /**
   * This function delete a line from a card
   * @params {
   *  lineIndex: number -> index of line to be deleted
   *  cardIndex: number -> index of card where is the line that will be deleted
   * }
   */
  const deleteLineFromCard = useCallback(
    ({ lineIndex, cardIndex }: { lineIndex: number; cardIndex: number }) => {
      const objectCopy = [...quotationItems];
      objectCopy[cardIndex].quotation.lines.splice(lineIndex, 1);
      setQuotationItems(objectCopy);
    },
    [quotationItems]
  );

  const toogleCardApproval = (cardIndex: number) => {
    const objectCopy = [...quotationItems];
    objectCopy[cardIndex].quotation.isApproved =
      !objectCopy[cardIndex].quotation.isApproved;
    setQuotationItems(objectCopy);
  };

  const updateLine = ({
    cardIndex,
    lineIndex,
    field,
    value,
  }: {
    cardIndex: number;
    lineIndex: number;
    field:
      | "label"
      | "description"
      | "pricePerUnit"
      | "discount"
      | "numberOfUnits";
    value: string | number;
  }) => {
    /**
     * Reference cards and lines
     */
    const objectCopy = [...quotationItems];
    const linesCopy = [...objectCopy[cardIndex].quotation.lines];

    /**
     * Update value
     */
    linesCopy[lineIndex][field] = value;

    /**
     * Update any other value that neeeds change
     */
    if (
      field === "pricePerUnit" ||
      field === "discount" ||
      field === "numberOfUnits"
    ) {
      linesCopy[lineIndex].amountToDiscount = calculateLinePercentage(
        linesCopy[lineIndex]
      );
      linesCopy[lineIndex].lineTotal = calculateLineSubtotal(
        linesCopy[lineIndex]
      );
    }

    objectCopy[cardIndex].quotation.lines = [...linesCopy];
    setQuotationItems(objectCopy);
  };

  /**
   * Calculate subtotal
   */
  const calculateLineSubtotal = useCallback((line: IQuotationLine) => {
    if (line.pricePerUnit <= 0 || line.numberOfUnits <= 0) return 0;
    if (line.discount === 0) return line.pricePerUnit * line.numberOfUnits;
    return line.pricePerUnit * line.numberOfUnits * (1 - line.discount / 100);
  }, []);

  /**
   * Calculate amount to discount in money
   */
  const calculateLinePercentage = useCallback((line: IQuotationLine) => {
    if (line.discount <= 0 || line.pricePerUnit <= 0 || line.numberOfUnits <= 0)
      return 0;
    return (line.discount / 100) * (line.pricePerUnit * line.numberOfUnits);
  }, []);

  /**
   * This function parse a number to currency format
   * @param price number
   * @returns string
   */
  const parseCurrency = (price: number): string =>
    new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 0,
    }).format(price);

  /*
  +-----------------------------------------------------------+
  |                                                           |
  |                         Effects                           |
  |                                                           |
  |___________________________________________________________+
  */
  useEffect(() => {
    setTotals(calculateCardsTotals(quotationItems));
    setSubtotals(calculateCardSubtotals(quotationItems));
  }, [quotationItems]);

  /*
  +-----------------------------------------------------------+
  |                                                           |
  |                      Components                           |
  |                                                           |
  |___________________________________________________________+
  */
  const TotalBox: FC<{ totals: ITotal }> = ({ totals }) => (
    <table className="total-box">
      <tbody>
        <tr>
          <td>Descuento</td>
          <td>{parseCurrency(totals.totalDiscount)}</td>
        </tr>
        <tr>
          <td>Total (IVA incluído si aplica)</td>
          <td>{parseCurrency(totals.totalToPay)}</td>
        </tr>
      </tbody>
    </table>
  );

  const SubtotalBox: FC<{ subtotals: ISubtotal[] }> = ({ subtotals }) => {
    if (subtotals.length === 0) return null;
    return (
      <table className="subtotal-box">
        <thead>
          <tr>
            <th>Paquete/Servicio</th>
            <th>Descuento</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {subtotals &&
            subtotals.map((subtotal, index: number) => (
              <tr key={`key-line-subtotal-${index}`}>
                <td>{subtotal.label}</td>
                <td>{parseCurrency(subtotal.amountToDiscount)}</td>
                <td>{parseCurrency(subtotal.subtotal)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const AddLine = () => (
    <Button
      className="--custom --underlined add-line-btn"
      type="link"
      onClick={addLineToCard}
    >
      + Agregar línea
    </Button>
  );

  const TableRowLine: FC<IFormLine> = ({ line, lineIndex, cardIndex }) => {
    const [label, setLabel] = useState<string>(line.label || "");
    const [description, setDescription] = useState<string>(
      line.description || ""
    );
    const [pricePerUnit, setPricePerUnit] = useState<number>(
      line.pricePerUnit || 0
    );
    const [discount, setDiscount] = useState<number>(line.discount || 0);
    const [numberOfUnits, setNumberOfUnits] = useState<number>(
      line.numberOfUnits || 0
    );

    return (
      <tr>
        <td className="label">
          {lineIndex === 0 && `${line.label} / mano de obra`}
          {lineIndex > 0 && (
            <TextArea
              disabled={isEditable === false}
              maxLength={512}
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              onBlur={() =>
                updateLine({
                  cardIndex: cardIndex,
                  lineIndex: lineIndex,
                  field: "label",
                  value: label,
                })
              }
            />
          )}
        </td>
        <td className="description">
          <TextArea
            disabled={isEditable === false}
            maxLength={512}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            onBlur={() =>
              updateLine({
                cardIndex: cardIndex,
                lineIndex: lineIndex,
                field: "description",
                value: description,
              })
            }
          />
        </td>
        <td className="price-per-unit">
          <InputNumber
            disabled={isEditable === false || line.editableLine === false}
            value={pricePerUnit}
            controls={false}
            min={0}
            addonBefore={"$"}
            onChange={(e) => e !== null && setPricePerUnit(e)}
            onBlur={() =>
              updateLine({
                cardIndex: cardIndex,
                lineIndex: lineIndex,
                field: "pricePerUnit",
                value: pricePerUnit,
              })
            }
          />
        </td>
        <td className="number-of-units">
          {lineIndex === 0 && line.numberOfUnits}
          {lineIndex > 0 && (
            <InputNumber
              disabled={isEditable === false}
              value={numberOfUnits}
              min={1}
              max={100}
              step={1}
              controls={false}
              onChange={(e) => e !== null && setNumberOfUnits(e)}
              onBlur={() =>
                updateLine({
                  cardIndex: cardIndex,
                  lineIndex: lineIndex,
                  field: "numberOfUnits",
                  value: numberOfUnits,
                })
              }
            />
          )}
        </td>
        <td className="discount">
          <div>
            <InputNumber
              disabled={isEditable === false}
              value={discount}
              min={0}
              max={100}
              step={1}
              addonAfter={"%"}
              controls={false}
              onChange={(e) => e !== null && setDiscount(e)}
              onBlur={() =>
                updateLine({
                  cardIndex: cardIndex,
                  lineIndex: lineIndex,
                  field: "discount",
                  value: discount,
                })
              }
            />
            <span>{parseCurrency(line.amountToDiscount)}</span>
          </div>
        </td>
        <td className="line-total">{parseCurrency(line.lineTotal)}</td>
        <td className="actions">
          {lineIndex === 0 && (
            <Checkbox
              className="--alter"
              disabled={isEditable === false}
              onClick={() => toogleCardApproval(cardIndex)}
              checked={quotationItems[cardIndex].quotation.isApproved}
            />
          )}
          {lineIndex !== 0 && isEditable && (
            <Button
              icon={<DeleteOutlined />}
              onClick={() => deleteLineFromCard({ cardIndex, lineIndex })}
            />
          )}
        </td>
      </tr>
    );
  };

  const CardLine: FC<IFormLine> = ({ line, lineIndex, cardIndex }) => {
    const [label, setLabel] = useState<string>(line.label || "");
    const [description, setDescription] = useState<string>(
      line.description || ""
    );
    const [pricePerUnit, setPricePerUnit] = useState<number>(
      line.pricePerUnit || 0
    );
    const [discount, setDiscount] = useState<number>(line.discount || 0);
    const [numberOfUnits, setNumberOfUnits] = useState<number>(
      line.numberOfUnits || 0
    );

    return (
      <div className="card-line">
        <div className="upper-line">
          <div className="identification">
            <div className="line-field label">
              <p>Concepto</p>
              {lineIndex === 0 && `${line.label} / mano de obra`}
              {lineIndex > 0 && (
                <TextArea
                  disabled={isEditable === false}
                  maxLength={512}
                  value={label}
                  onChange={(e) => setLabel(e.target.value)}
                  onBlur={() =>
                    updateLine({
                      cardIndex: cardIndex,
                      lineIndex: lineIndex,
                      field: "label",
                      value: label,
                    })
                  }
                />
              )}
            </div>
            <div className="line-field description">
              <p>Descripción</p>
              <TextArea
                disabled={isEditable === false}
                maxLength={512}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onBlur={() =>
                  updateLine({
                    cardIndex: cardIndex,
                    lineIndex: lineIndex,
                    field: "description",
                    value: description,
                  })
                }
              />
            </div>
          </div>
          <div className="actions">
            {lineIndex === 0 && (
              <Checkbox
                className="--alter"
                disabled={isEditable === false}
                onClick={() => toogleCardApproval(cardIndex)}
                checked={quotationItems[cardIndex].quotation.isApproved}
              />
            )}
            {lineIndex !== 0 && isEditable && (
              <Button
                icon={<DeleteOutlined />}
                onClick={() => deleteLineFromCard({ cardIndex, lineIndex })}
              />
            )}
          </div>
        </div>
        <div className="lower-line">
          <div className="line-field price-per-unit">
            <p>Precio unit.</p>
            <InputNumber
              disabled={isEditable === false}
              value={pricePerUnit}
              controls={false}
              min={0}
              addonBefore={"$"}
              onChange={(e) => e !== null && setPricePerUnit(e)}
              onBlur={() =>
                updateLine({
                  cardIndex: cardIndex,
                  lineIndex: lineIndex,
                  field: "pricePerUnit",
                  value: pricePerUnit,
                })
              }
            />
          </div>
          <div className="line-field number-of-units">
            <p>Cant.</p>
            {lineIndex === 0 && line.numberOfUnits}
            {lineIndex > 0 && (
              <InputNumber
                disabled={isEditable === false}
                value={numberOfUnits}
                min={1}
                max={100}
                step={1}
                controls={false}
                onChange={(e) => e !== null && setNumberOfUnits(e)}
                onBlur={() =>
                  updateLine({
                    cardIndex: cardIndex,
                    lineIndex: lineIndex,
                    field: "numberOfUnits",
                    value: numberOfUnits,
                  })
                }
              />
            )}
          </div>
          <div className="line-field discount">
            <p>Descuento</p>
            <div>
              <InputNumber
                disabled={isEditable === false}
                value={discount}
                min={0}
                max={100}
                step={1}
                addonAfter={"%"}
                controls={false}
                onChange={(e) => e !== null && setDiscount(e)}
                onBlur={() =>
                  updateLine({
                    cardIndex: cardIndex,
                    lineIndex: lineIndex,
                    field: "discount",
                    value: discount,
                  })
                }
              />
              <span>{parseCurrency(line.amountToDiscount)}</span>
            </div>
          </div>
          <div className="line-field line-total">
            <p>Subtotal</p>
            {parseCurrency(line.lineTotal)}
          </div>
        </div>
      </div>
    );
  };

  const QuotationItemCard: FC<{ quotationItem: any; cardIndex: number }> = ({
    quotationItem,
    cardIndex,
  }) => {
    if (quotationItem === undefined) return null;
    if (quotationItem.quotation === undefined) return null;
    return (
      <div className="quotation-item-card">
        <p className="card-pagination-label">
          Solicitud de servicio {cardIndex + 1} de {quotationItems.length}
        </p>
        <p className="card-title-label">{quotationItem.quotationTitle}</p>
        {isMobileScreen === false && (
          <table>
            <thead>
              <tr>
                <th className="label">Concepto</th>
                <th className="description">Descripción</th>
                <th className="price-per-unit">Precio unit.</th>
                <th className="number-of-units">Cant.</th>
                <th className="discount">Descuento</th>
                <th className="line-total">Subtotal</th>
                <th className="actions" />
              </tr>
            </thead>
            <tbody>
              {quotationItem.quotation.lines &&
                quotationItem.quotation.lines.map(
                  (line: IQuotationLine, lineIndex: number) => (
                    <TableRowLine
                      line={line}
                      lineIndex={lineIndex}
                      cardIndex={cardIndex}
                      key={`table-row-line-${cardIndex}-${lineIndex}`}
                    />
                  )
                )}
            </tbody>
          </table>
        )}
        {isMobileScreen === true && (
          <div className="card-form">
            {quotationItem.quotation.lines &&
              quotationItem.quotation.lines.map(
                (line: IQuotationLine, lineIndex: number) => (
                  <CardLine
                    line={line}
                    lineIndex={lineIndex}
                    cardIndex={cardIndex}
                    key={`card-line-${cardIndex}-${lineIndex}`}
                  />
                )
              )}
          </div>
        )}
      </div>
    );
  };

  /*
  +-----------------------------------------------------------+
  |                                                           |
  |                     Main Component                        |
  |                                                           |
  |___________________________________________________________+
  */
  return (
    <section className="quotation-forms">
      <Button
        disabled={
          (quotationItems && quotationItems.length <= 1) || activeCard === 0
        }
        className="left"
        style={{ zIndex: "3000" }}
        onClick={() =>
          setTimeout(() => {
            prevCard();
          }, 100)
        }
      >
        <LeftOutlined id="left" />
      </Button>

      <div className="content-wrapper">
        <Carousel
          ref={carouselRef}
          swipeToSlide={false}
          swipe={false}
          infinite={false}
          dots={false}
          arrows={false}
          slidesToScroll={1}
          slidesToShow={1}
          rows={1}
          slidesPerRow={1}
          draggable={true}
          adaptiveHeight={true}
          initialSlide={activeCard}
        >
          {quotationItems &&
            quotationItems.map((quotationItem: any, cardIndex: number) => (
              <QuotationItemCard
                key={cardIndex}
                cardIndex={cardIndex}
                quotationItem={quotationItem}
              />
            ))}
        </Carousel>
        {quotationItems && quotationItems.length > 1 && (
          <SubtotalBox subtotals={subtotals} />
        )}
        <TotalBox totals={totals} />
        {isEditable && <AddLine />}
      </div>

      <Button
        disabled={
          (quotationItems && quotationItems.length <= 1) ||
          activeCard === quotationItems.length - 1
        }
        className="right"
        style={{ zIndex: "3000" }}
        onClick={() =>
          setTimeout(() => {
            nextCard();
          }, 100)
        }
      >
        <RightOutlined id="right" />
      </Button>
    </section>
  );
};

export default memo(QuotationForms);
