import React, { FC, memo } from "react";
import { contactChannels, TContactChannels } from "..";
import { CkButton } from "../../../../../../../CkUI";
import "./styles.css";

interface IProps {
  selectedOption: TContactChannels | undefined;
  setSelectedOption: Function;
}

const OptionsGrid: FC<IProps> = ({
  selectedOption,
  setSelectedOption,
}) => {

  return (
    <div className="options-grid">
      {contactChannels.map((option, index) => (
        <CkButton
          variant={selectedOption === option.value ? "primary" : "secondary"}
          onClick={() => setSelectedOption(option.value)}
          key={index}
        >
          {option.label}
        </CkButton>
      ))}
    </div>
  );
};

export default memo(OptionsGrid);
