import React, { useContext, useState, useEffect, FC, memo, useRef} from "react";
import { ArrowLeftOutlined, LeftOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Divider,
  Button,
  Row,
  Image,
  Form,
  DatePicker,
  DatePickerProps,
  TimePicker,
  Select,
  Input
} from "antd";
import { getSasToken } from "../../../../../../stores/selectors";
import { SasTokenHandler } from "../../../../../Utilities";
import { useWindowSize } from "../../../../../../app/Utilities/WindowSize";
import { CurrentUser } from "../../../../../../context/UserContext";
import * as CatalogAPI from "shared/src/services/catalogsAPI";
import { RoleCode } from "shared";

import {
  Appointment,
  serviceSAS
} from "shared";
import { WorkshopService, WorkHours } from "shared/src/data-models/Workshop";

import avatar from "../../../../../../assets/Images/avatar placeholder.svg";
//Images & Icons
import {
  CalendarIcon2,
  ClockIcon
} from "../../../../../../assets/SvgIcons";

import moment from "moment";

import "./style.css";

const TokenTimeToRefresh = 180000;

interface IWorkshopHours {
  dayCode: number;
  openTime: string;
  closeTime: string;
  isOpen: boolean;
}

interface IDaysToHandle {
  dayCode: number;
  isOpen: boolean;
  workingHours: string[];
}

interface IProps {
  appointmentTracking: any;
  appointmentInfo: Appointment;
  hours: WorkHours[];
  workshopId: string;
  currentDate: moment.Moment | undefined;
  SetCurrentDate: Function;
  currentTime: string | undefined;
  SetCurrentTime: Function;
  isEditable: boolean;
  onClose: Function;
}

const QuotationHeader: FC<IProps> = ({
  appointmentTracking,
  appointmentInfo,
  hours,
  workshopId,
  currentDate,
  SetCurrentDate,
  currentTime,
  SetCurrentTime,
  isEditable,
  onClose
}) => {
  /** States */
  const dispatch = useDispatch();
  const sasToken: string = useSelector(getSasToken);
  const sasTokenHandler = new SasTokenHandler('');
  const user = useContext(CurrentUser);
  const windowSize = useWindowSize();
  const [userRole, setUserRole] = useState<string>('');
  // Working hours
  const [workshopWorkingHours, setWorkshopWorkingHours] = useState<
    string[]
  >([]); 
  const intervalID = useRef<any>(null);
  const [minimunDateTime, setMinimumDatetime] = useState<any>();

  /** Functions */
  const getSasTokenAzure = async () => {
    dispatch(serviceSAS());
  };

  const getRoleName = (roleCode: RoleCode | ''): string => {
    if (roleCode === "ADM") return "Administrador";
    if (roleCode === "OWN") return "Dueño";
    if (roleCode === "MEC") return "Mecánico"; 
    return "";
  }

  // Day to handle
  const dayToHandle = () : { dayCode: number; isMinimumDate: boolean } => {
    const date = currentDate || moment();
    const result = {
      dayCode: date.day() === 0 ? 7 : date.day(),
      isMinimumDate: false
    };
    if (minimunDateTime !== undefined) {
      const pickedDate = date.format("YYYY-MM-DD");
      const minimumDate = minimunDateTime.format("YYYY-MM-DD");
      result.isMinimumDate = pickedDate === minimumDate;
    }
    return result;
  };

  // Query working hours
  const hoursToHandle = (workingHours: WorkHours[], allHours: boolean = false, allDays: boolean = false): IDaysToHandle[] => {
    /**
     * Set all hours in case allHours is turnedOn
     */
    const hours = allHours === false ? [...workingHours] : workingHours.map((hour) => {
      return {
        ...hour,
        closeTime: "05:30:00.724Z",
        openTime:"06:00:00.487Z"
      }
    })
    return hours.map((day) => {
      /**
       * What happend if we have an invalid date
       */
      if (moment(day.openTime, "HH:mmZZ").isValid() === false || moment(day.closeTime, "HH:mmZZ").isValid() === false) {
        return {
          dayCode: day.dayCode,
          isOpen: allDays ? allDays : day.isOpen,
          workingHours: [],
        };
      }

      /** 
       * Calculate intervals 
       */
      const intervals: string[] = [];
      const currentDate = moment().format("YYYY-MM-DD");
      var intervalDateTime = moment(`${currentDate} ${day.openTime}`,  "YYYY-MM-DD HH:mmZZ");
      var closeDateTime = moment(`${currentDate} ${day.closeTime}`,  "YYYY-MM-DD HH:mmZZ");
      
      /** If CloseDateTime is lower or is the same than OpenDateTime, add one day */
      if (closeDateTime.diff(intervalDateTime) <= 0) {
        closeDateTime.add(1, "day");
      }

      var iterationsMade = 0;
      while ((closeDateTime.diff(intervalDateTime) > 0 || iterationsMade === 48) ) {
        intervals[intervals.length] = intervalDateTime.format("HH:mm");
        intervalDateTime.add(30, "m");
        iterationsMade += 1;
      }
      
      return {
        dayCode: day.dayCode,
        isOpen: allDays ? allDays : day.isOpen,
        workingHours: intervals,
      };
    });
  };

  const filterHours = (workingHours: string[], reference: string) => {
    const splitedReference = reference.split(":");
    const referenceDate = moment().hour(parseInt(splitedReference[0])).minute(parseInt(splitedReference[1]));
    const intervalDate = moment();
    return workingHours.filter((hour:string) => {
      const splited = hour.split(":");
      intervalDate.hour(parseInt(splited[0])).minute(parseInt(splited[1]));
      return intervalDate.diff(referenceDate, "minute") > -29;
    });
  }

  const getWorkingHours = () => {
    const day = dayToHandle();
    const workingHours = hoursToHandle(hours, true, true);
    if (day && workingHours && workingHours.length > 0 && workingHours.find((value) => value.dayCode === day.dayCode) !== undefined) {
      const hours: string[] = workingHours.find((value) => value.dayCode === day.dayCode)?.workingHours || [];
      setWorkshopWorkingHours(day.isMinimumDate ? [...(filterHours([...hours], minimunDateTime.format("HH:mm")))] : [...hours]);
    } else {
      setWorkshopWorkingHours([]);
    }
  };

  const getClosestHour = (workingHours: string[]) => {
    const closestHourIndex = workingHours.findIndex((hour) => {
      const splitedHour = hour.split(":");
      const differenceOfHours = moment().diff(moment().minutes(parseInt(splitedHour[1])).hours(parseInt(splitedHour[0])), "minutes");
      return (differenceOfHours < 0 || (differenceOfHours > 5 && differenceOfHours < 25));
    });
    return closestHourIndex;
  }

  /** Effects */
  useEffect(() => {
    if (user === undefined || workshopId === undefined) return;
    setUserRole(getRoleName(user?.userRoles?.find(item => item.workshopId === workshopId)?.roleCode || ''));
  }, [user, workshopId])
  
  /* 
  useEffect(() => {
    hours && getWorkingHours();
  }, [hours, minimunDateTime, currentDate]);

  useEffect(() => {
    if (workshopWorkingHours.length > 0 && isEditable) {
      const closestHour = getClosestHour(workshopWorkingHours);
      SetCurrentTime(workshopWorkingHours[closestHour || 0]);
    }
  }, [workshopWorkingHours]);
  */

  useEffect(() => {
    intervalID.current = setInterval(() => {
      const tokenValidation = sasTokenHandler.setToken(sasToken);
    if(tokenValidation.isValid === false) getSasTokenAzure();
    if (tokenValidation.isValid === true && tokenValidation.validTime < TokenTimeToRefresh) window.setTimeout(getSasTokenAzure, tokenValidation.validTime);
    }, TokenTimeToRefresh);
    return () => {
      intervalID.current && clearInterval(intervalID.current);
    };
  }, []);

  useEffect(() => {
    const tokenValidation = sasTokenHandler.setToken(sasToken);
    if(tokenValidation.isValid === false) getSasTokenAzure();
    if (tokenValidation.isValid === true && tokenValidation.validTime < TokenTimeToRefresh) window.setTimeout(getSasTokenAzure, tokenValidation.validTime);
  }, [sasToken]);

  useEffect(() => {
    isEditable && SetCurrentDate(moment());
    isEditable && SetCurrentTime(moment().format("HH:mm"));
    /**
     * We must set the minimum datetime that can be used for this form
     */
    if (appointmentInfo?.appointmentHistoryStatus !== undefined) {
      const minimumDateTimeObj = appointmentInfo?.appointmentHistoryStatus.filter((appointmentStatus: any) => appointmentStatus.statusCode === "DIAG")[0] || undefined;
      if (minimumDateTimeObj !== undefined) {
        console.info('minimumDateTimeObj.statusModifiedDate', minimumDateTimeObj.statusModifiedDate);
        setMinimumDatetime(moment(minimumDateTimeObj.statusModifiedDate.endsWith("Z") ? minimumDateTimeObj.statusModifiedDate : `${minimumDateTimeObj.statusModifiedDate}Z`));
      } else {
        setMinimumDatetime(moment(appointmentInfo.startDateTime));
      }
    } else {
      setMinimumDatetime(moment(appointmentInfo.startDateTime));
    }
  }, []);

  /** Components */
  const UserCard = () => (
    <div className="user-card">
      {sasToken !== '' && <Image
        preview={false}
        src={`${user?.photoUrl}?${sasToken}`}
        fallback={avatar}
      />}
      <div className="info">
        <p className="semibold">
          {user?.name} {user?.lastName}
        </p>
        {userRole !== '' && <p>{userRole}</p>}
      </div>
    </div>
  );

  const VehicleCard = () => (
    <div className="field">
      <p className="description">Usuario</p>
      <div className="vehicle-card">
        {appointmentInfo?.vehicle?.photoUrl && sasToken !== '' &&
          <Image
            preview={false}
            src={`${appointmentInfo.vehicle.photoUrl}?${sasToken}`}
          />}
        <div className="info">
          {appointmentInfo?.vehicle?.nickname && <p className="semibold">{appointmentInfo?.vehicle?.nickname}</p>}
          <p>
            {appointmentInfo?.vehicle?.brandName && appointmentInfo?.vehicle?.brandName} {appointmentInfo?.vehicle?.modelName && appointmentInfo?.vehicle?.modelName}
          </p>
        </div>
      </div>
    </div>
  )

  const FolioDisplay = () => (
    <div className="field folio-display">
      <p className="description">Folio</p>
      <div className="orange-container">
        <p>{appointmentTracking && appointmentTracking?.invoiceNo && appointmentTracking.invoiceNo} - {appointmentTracking && appointmentTracking?.version && appointmentTracking.version}</p>
      </div>
    </div>
  )

  const CloseButton = () => (
    <Button style={{ border: 0, marginTop: "10px", padding: 0 }} onClick={() => onClose()}>
      {windowSize.width > 1200 ? (
        <ArrowLeftOutlined style={{ fontSize: "24px" }} />
      ) : (
          <span style={{ color: "#2f66f2" }}>
            <LeftOutlined /> Regresar
          </span>
        )}
    </Button>
  );
  
  return (
    <div className="quotation-header">
      <Row>
        <Col xs={24} sm={12}>
          <div style={{ display: windowSize.width > 1200 ? "flex" : "block" }}>
            <CloseButton />
            <h1 className="drawer-title">Cotización</h1>  
          </div>
        </Col>
        <Col xs={0} sm={12} className="user-card-container">
          <UserCard />
        </Col>
        <Col span={24}>
          <p className="drawer-instructions">
            Completa los datos para generar una cotización
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 6, order: 1 }}>
          <VehicleCard />
        </Col>
        <Col xs={{ span: 24, order: 3 }} md={{ span: 12, order: 3 }} lg={{ span: 6, order: 2 }}>
          <div className="field">
            <p className="description-gray">Fecha</p>
            <DatePicker
              className="picker-size --alter move-clear-date"
              disabled
              inputReadOnly
              value={currentDate}
              placeholder="DD/MM/AAAA"
              suffixIcon={<CalendarIcon2 />}
              format={"dddd DD/MM/yy"}
            />
          </div>
        </Col>
        <Col xs={{ span: 24, order: 4 }} md={{ span: 12, order: 4 }} lg={{ span: 6, order: 3 }}>
          <div className="field">
            <p className="description-gray">Hora</p>
            <Input
              className="picker-size --alter"
              disabled
              value={currentTime}
              placeholder="00:00 PM"
              suffix={<ClockIcon />}
            />
          </div>
        </Col>
        <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }} lg={{ span: 6, order: 4 }}>
          <FolioDisplay />
        </Col>
      </Row>
    </div>
  );
};

export default memo(QuotationHeader);
