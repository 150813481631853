import React, { FC, useEffect, useState, memo, useCallback } from "react";
import { Drawer, Spin, message } from "antd";
import { useSelector } from "react-redux";
import { Appointment } from "shared";
import { WorkshopService, WorkHours } from "shared/src/data-models/Workshop";
import { AppointmentAPI } from "../../../../Services/axios";
import { CloseOutlined, LeftOutlined } from "@ant-design/icons";

import QuotationHeader from "./QuotationHeader";
import QuotationForms from "./QuotationForms";
import QuotationFooter from "./QuotationFooter";
import QuotationApprovalModal, { TContactChannels } from "./QuotationApprovalModal";

import moment from "moment";

import { CalendarDeleteIcon } from "../../../../../assets/SvgIcons";
import { ConfirmationModal } from "../../ConfirmationModal";

import { getWorkshops } from "../../../../../stores/selectors";
import { CkMessage } from "../../../../../CkUI";
import "./styles.css";

export type TQuotationStatus = "DRAFT" | "WAITING_APPROVAL" | "APPROVED";

export interface IQuotationLine {
  id: number;
  label: string;
  description: string;
  pricePerUnit: number;
  numberOfUnits: number;
  discount: number;
  amountToDiscount: number;
  lineTotal: number;
  editableLine?: boolean;
}
export interface IQuotationItem {
  id: number;
  isApproved: boolean;
  lines: IQuotationLine[];
}

export interface IFormLine {
  line: IQuotationLine;
  lineIndex: number;
  dIndex: number;
}
export interface ITotal {
  totalDiscount: number;
  totalToPay: number;
}
export interface ISubtotal {
  label: string;
  amountToDiscount: number;
  subtotal: number;
}

/**
 *
 */
export const calculateCardSubtotals = (quotationItems: any[]): ISubtotal[] => {
  return quotationItems
    .filter((quotationItem) => quotationItem.quotation.isApproved)
    .map((quotationItem): ISubtotal => {
      return {
        label: quotationItem.quotation.lines[0].label,
        amountToDiscount: quotationItem.quotation.lines.reduce(
          (prev: number, current: IQuotationLine) =>
            prev + current.numberOfUnits * current.amountToDiscount,
          0
        ),
        subtotal: quotationItem.quotation.lines.reduce(
          (prev: number, current: IQuotationLine) => prev + current.lineTotal,
          0
        ),
      };
    });
};

/**
 * Calculate totals
 */
export const calculateCardsTotals = (quotationItems: any[]): ITotal => {
  const subtotals = quotationItems
    .filter((quotationItem) => quotationItem.quotation.isApproved)
    .map((quotationItem): ISubtotal => {
      return {
        label: quotationItem.quotation.lines[0].label,
        amountToDiscount: quotationItem.quotation.lines.reduce(
          (prev: number, current: IQuotationLine) =>
            prev + current.numberOfUnits * current.amountToDiscount,
          0
        ),
        subtotal: quotationItem.quotation.lines.reduce(
          (prev: number, current: IQuotationLine) => prev + current.lineTotal,
          0
        ),
      };
    });
  return {
    totalDiscount: subtotals.reduce(
      (prev: number, current: ISubtotal) => prev + current.amountToDiscount,
      0
    ),
    totalToPay: subtotals.reduce(
      (prev: number, current: ISubtotal) => prev + current.subtotal,
      0
    ),
  };
};

interface IProps {
  quotationConfirmation: boolean;
  setQuotationConfirmation: Function;
  appointmentInfo: Appointment;
  appointmentId: string;
  hours: WorkHours[];
  workshopId: string;
  services: WorkshopService[];
  packages: any[];
  coworkers: Array<any>;
  handleConfirm: Function;
}

const QuotationFormModal: FC<IProps> = ({
  quotationConfirmation,
  setQuotationConfirmation,
  appointmentInfo,
  appointmentId,
  hours,
  workshopId,
  handleConfirm,
}) => {
  /** States */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [completeProcess, setCompleteProcess] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [appointmentTracking, setAppointmentTracking] = useState<any>();
  const [quotationItems, setQuotationItems] = useState<any[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);
  const workshops = useSelector(getWorkshops);

  /**
   * data needed and used in quotation header
   */
  const [currentDate, SetCurrentDate] = useState<moment.Moment>();
  const [currentTime, SetCurrentTime] = useState<string>();

  /** quotation status, setted as approved by default, to prevent unwanted changes in case that something goes wrong on load  */
  const [quotationStatus, setQuotationStatus] =
    useState<TQuotationStatus>("APPROVED");
  /** request confirmation before close modal  */
  const [confirmCloseModal, setConfirmCloseModal] = useState<boolean>(false);
  /** display or not modal to request approval */
  const [showQuotationApprovalModal, setShowQuotationApprovalModal] =
    useState<boolean>(false);

  /*------------------------------------------------*
  |                      Functions                   |
  *-------------------------------------------------*/

  const clearDrawer = () => {
    setAppointmentTracking(undefined);
    SetCurrentDate(undefined);
    SetCurrentTime(undefined);
    setQuotationItems([]);
    setQuotationStatus("APPROVED");
    setShowQuotationApprovalModal(false);
    setQuotationConfirmation(false);
  };

  /**
   * This function validate if the provided quotationItems are valid to be submited
   * -quotationItems must have at least one element
   * -quotationItems subtotals must have a price greater than 0
   * -
   * @params quotationItems: any[]
   */
  const validateQuotationItems = (
    quotationItems: any[]
  ): { isValid: boolean; message?: string } => {
    const subtotal = calculateCardSubtotals(quotationItems);

    if (subtotal.length === 0)
      return {
        isValid: false,
        message: "Debe aprobar al menos un servicio para continuar.",
      };

    if (subtotal.some((item) => item.subtotal === 0))
      return {
        isValid: false,
        message: "Todos los servicios cotizados deben tener un precio válido.",
      };

    if (
      quotationItems
        .filter((quotationItem) => quotationItem.quotation.isApproved)
        .some((quotationItem) =>
          quotationItem.quotation.lines.some(
            (line: IQuotationLine) => line.pricePerUnit === 0
          )
        )
    )
      return {
        isValid: false,
        message: "Cada linea cotizada debe tener un precio válido.",
      };

    if (
      quotationItems
        .filter((quotationItem) => quotationItem.quotation.isApproved)
        .some((quotationItem) =>
          quotationItem.quotation.lines.some(
            (line: IQuotationLine) => line.label.length === 0
          )
        )
    )
      return {
        isValid: false,
        message: "Debe indicar el concepto de cada linea cotizada.",
      };

    /* 
    if (quotationItems.filter(quotationItem => quotationItem.quotation.isApproved).some(quotationItem => quotationItem.quotation.lines.some((line: IQuotationLine) => line.description.length === 0))) return {
      isValid: false,
      message: "Debe agregar una descripción para linea cotizada."
    } 
    */

    return {
      isValid: true,
      message: "",
    };
  };

  /**
   * Translate services and packages to quotationItems
   * @param param {
   *  packages,
   *  services
   * }
   */
  const translateServiceToQuotationItem = ({
    packages,
    services,
    workshop,
    quotationStatus,
  }: {
    packages: any[];
    services: any[];
    workshop: any;
    quotationStatus: TQuotationStatus;
  }) => {
    const checkedPackages = packages.map((pack) => {
      const quotationTitle = `${pack.servicePackageSet.servicePackage.servicePackageDesc} - ${pack.servicePackageSet.servicePackageType.servicePackageTypeDesc}`;
      /**
       * Empty item
       */
      const quotation: IQuotationItem = {
        id: 0,
        isApproved: true,
        lines: [
          {
            id: 0,
            label: quotationTitle,
            description: "", // Must be retrieved from backend
            pricePerUnit: 0, // Must be retrieved from backend
            numberOfUnits: 1,
            discount: 0,
            amountToDiscount: 0,
            lineTotal: 0, // calculate if price is provided on backend
            editableLine: true,
          },
        ],
      };

      /**
       * check if package have quotation
       */
      if ("quotation" in pack) {
        quotation.id = pack.quotation.id || 0;
        quotation.isApproved =
          quotationStatus === "DRAFT"
            ? true
            : pack.quotation.isQuotationApproved;
        if (
          pack.quotation.packagesLines &&
          pack.quotation.packagesLines.length > 0
        )
          quotation.lines = [...pack.quotation.packagesLines];
      }

      /**
       * check if we need to search first line price
       */
      const firstLine = quotation.lines[0];
      if (firstLine.id === 0) {
        const currentWorkshopPackages =
          workshops.filter((item) => item.id === workshop.id)[0]?.packages ||
          [];
        const currentPackageInfo = currentWorkshopPackages.filter(
          (currentPack) =>
            currentPack.servicePackageSetId === pack.servicePackageSetId
        )[0];
        if (currentPackageInfo !== undefined) {
          firstLine.pricePerUnit = currentPackageInfo.price;
          firstLine.lineTotal = currentPackageInfo.price;
          firstLine.description = currentPackageInfo.comment;
          firstLine.editableLine = currentPackageInfo.price === 0;
        }
      } else {
        firstLine.editableLine = firstLine.pricePerUnit === 0;
      }
      quotation.lines[0] = firstLine;

      return {
        ...pack,
        quotationTitle: quotationTitle,
        quotationItemType: "package",
        quotation: quotation,
      };
    });

    const checkedServices = services.map((service) => {
      const quotationTitle = service.service.serviceName;
      /**
       * Empty item
       */
      const quotation: IQuotationItem = {
        id: 0,
        isApproved: true,
        lines: [
          {
            id: 0,
            label: quotationTitle,
            description: "", // Must be retrieved from backend
            pricePerUnit: 0, // Must be retrieved from backend
            numberOfUnits: 1,
            discount: 0,
            amountToDiscount: 0,
            lineTotal: 0, // calculate if price is provided on backend
          },
        ],
      };

      /**
       * check if service have quotation
       */
      if ("quotation" in service) {
        quotation.id = service.quotation.id || 0;
        quotation.isApproved =
          quotationStatus === "DRAFT"
            ? true
            : service.quotation.isQuotationApproved;
        if (
          service.quotation.servicesLines &&
          service.quotation.servicesLines.length > 0
        )
          quotation.lines = [...service.quotation.servicesLines];
      }

      return {
        ...service,
        quotationTitle: quotationTitle,
        quotationItemType: "service",
        quotation: quotation,
      };
    });
    setQuotationItems([...checkedPackages, ...checkedServices]);
  };

  /**
   * Filter quotation items and return them to be sent to API
   */
  const getFilteredQuotationItems = (quotationItems: any[]) => {
    /**
     * Creating objects packages with a new interface for the payload
     */
    const packages = quotationItems
      .filter((item) => item.quotationItemType === "package")
      .map((item) => {
        return {
          Id: item.quotation.id,
          isQuotationApproved: item.quotation.isApproved,
          PackagesLines: item.quotation.lines,
        };
      });

    /**
     * Creating objects services with a new interface for the payload
     */
    const services = quotationItems
      .filter((item) => item.quotationItemType === "service")
      .map((item) => {
        return {
          Id: item.quotation.id,
          isQuotationApproved: item.quotation.isApproved,
          ServicesLines: item.quotation.lines,
        };
      });

    return {
      packages,
      services,
    };
  };

  /**
   * This function will set the quotation in draft status
   */
  const setDraftQuotation = async () => {
    const items = getFilteredQuotationItems(quotationItems);

    /**
     * Creating the payload with QuotationStatus `draft`
     */
    const payload = {
      WorkshopAppointmentTrackingId: appointmentTracking.appointmentTrackingId,
      QuotationStatus: "DRAFT",
      ServicesQuotation: items.services,
      PackagesQuotation: items.packages,
    };

    /**
     * Calling service with the current payload
     */
    setIsLoading(true);
    AppointmentAPI.saveQuotationDraft(payload)
      .then((response) => {
        if (response.data) {
          CkMessage({type: "success", text: "Borrador guardado exitosamente."});
          // setUpdateAppoTrigger(true);
          clearDrawer();
        } else {
          CkMessage({
            type: "error",
            text: "Ups, ocurrio un problema al guardar borrador."
          });
        }
      })
      .catch(() =>
        CkMessage({type: "error", text: "Ups, ocurrio un problema al guardar borrador."})
      )
      .finally(() => setIsLoading(false));
  };

  /**
   * This function will set quotation in waitingApproval and send it to user
   */
  const sendQuotation = async () => {
    const validate = validateQuotationItems(quotationItems);

    if (validate.isValid === false) {
      CkMessage({type: "error", text: validate.message});
      return;
    }

    const items = getFilteredQuotationItems(quotationItems);

    /**
     * Creating the payload with QuotationStatus `waitingApproval`
     */
    const payload = {
      WorkshopAppointmentTrackingId: appointmentTracking.appointmentTrackingId,
      QuotationStatus: "WAITING_APPROVAL",
      ServicesQuotation: items.services,
      PackagesQuotation: items.packages,
    };

    /**
     * Calling service with the current payload
     */
    setIsLoading(true);
    AppointmentAPI.sendQuotationToEndConsumer(payload)
      .then((response) => {
        if (response.data) {
          CkMessage({
            type: "success",
            text: "Cotización enviada al cliente exitosamente."
          });
          clearDrawer();
          handleConfirm("QUOT");
        } else {
          CkMessage({
            type: "error",
            text: "Ups, ocurrio un problema al enviar la cotización al cliente."
          });
        }
      })
      .catch(() =>
        CkMessage({
          type: "error",
          text: "Ups, ocurrio un problema al enviar la cotización al cliente."
        })
      )
      .finally(() => setIsLoading(false));
  };

  /**
   * This function approves the quotation
   * @param comment string
   * @returns
   */
  const approveQuotation = async (approvalData: {
    comment?: string;
    imageSupport?: string;
    contactedVia: TContactChannels;
  }) => {
    const validate = validateQuotationItems(quotationItems);

    if (validate.isValid === false) {
      CkMessage({type: "error", text: validate.message});
      return;
    }

    if (approvalData.contactedVia === undefined) {
      CkMessage({
        type: "error",
        text: "Debe indicar el canal por el cual el cliente fue contactado para la aprobación."
      });
      return;
    }

    const items = getFilteredQuotationItems(quotationItems);

    /**
     * Creating the payload with QuotationStatus `approved`
     */
    const payload = {
      WorkshopAppointmentTrackingId: appointmentTracking.appointmentTrackingId,
      QuotationStatus: "APPROVED",
      QuotationComment: approvalData.comment || null,
      QuotationApprovedByWorkshop: true,
      QuotationUserContactedVia: approvalData.contactedVia,
      QuotationApprovedByWorkshopSupport: approvalData.imageSupport || null,
      ServicesQuotation: items.services,
      PackagesQuotation: items.packages,
    };

    /**
     * Calling service with the current payload
     */
    setIsLoading(true);
    AppointmentAPI.approveQuotation(payload)
      .then((response) => {
        if (response.data) {
          CkMessage({type: "success", text: "La cotización fue aprobada exitosamente."});
          clearDrawer();
          handleConfirm("QUOT");
        } else {
          CkMessage({
            type: "error",
            text: "Ups, ocurrio un problema al aprobar la cotización."
          });
        }
      })
      .catch(() =>
        CkMessage({
          type: "error",
          text: "Ups, ocurrio un problema al aprobar la cotización."
        })
      )
      .finally(() => setIsLoading(false));
  };

  /**
   * @param status new status of type TQuotationStatus
   * This functions handles the update of the status
   * Before update the status, form data must be save
   */
  const updateQuotationStatus = (
    newStatus: TQuotationStatus | "FORCE",
    approvalData?: {
      comment?: string;
      imageSupport?: string;
      contactedVia: TContactChannels;
    }
  ) => {
    if (newStatus === "DRAFT") setDraftQuotation();
    if (newStatus === "WAITING_APPROVAL") sendQuotation();
    if (newStatus === "APPROVED" && approvalData) approveQuotation(approvalData);
    if (newStatus === "APPROVED" && !approvalData) return undefined;
    if (newStatus === "FORCE") {
      clearDrawer();
    }
  };

  /**
   * This function opens the confirmation modal that notifies the user
   * that changes will be performed, and after this, quotation form can't be change
   */
  const requestApproval = () => {
    const validate = validateQuotationItems(quotationItems);

    if (validate.isValid === false) {
      CkMessage({type: "error", text: validate.message});
      return;
    }

    setShowQuotationApprovalModal(true);
  };

  /**
   * Send a reminder to the user requesting approval
   */
  const sendReminder = () => {};

  const getAppointmentTracking = async () => {
    AppointmentAPI.getTrackingReception(appointmentId)
      .then((response) => {
        // If response is successfull
        if (response && response.data) {
          setQuotationStatus(response?.data?.quotationStatus || "DRAFT");
          setIsEditable(response?.data?.quotationStatus !== "APPROVED");

          translateServiceToQuotationItem({
            packages: Array.isArray(response?.data?.packagesRequired)
              ? response.data.packagesRequired
              : [],
            services: Array.isArray(response?.data?.servicesRequired)
              ? response.data.servicesRequired
              : [],
            workshop: response?.data?.workshop,
            quotationStatus: response?.data?.quotationStatus || "DRAFT",
          });

          const quotationDate = appointmentInfo.appointmentHistoryStatus.filter(
            (status: any) => status.statusCode === "QUOT"
          )[0];

          if (quotationDate !== undefined) {
            const date = moment(
              quotationDate.statusModifiedDate.endsWith("Z")
                ? quotationDate.statusModifiedDate
                : `${quotationDate.statusModifiedDate}Z`
            );
            SetCurrentDate(date);
            SetCurrentTime(date.format("HH:mm"));
          }

          setAppointmentTracking(response.data);
        } else {
          // if is sucessfull but something happened and data is not defined
          setQuotationConfirmation(false);
          setAppointmentTracking(undefined);
          CkMessage({
            type: "error",
            text: "Ups, no pudimos obtener la información. Intenta mas tarde"
          });
          return;
        }
      })
      // If we catch an error
      .catch((error) => {
        setAppointmentTracking(undefined);
        setQuotationConfirmation(false);
        console.error("error", error);
        CkMessage({
          type: "error",
          text: "Ups, no pudimos obtener la información. Intenta mas tarde"
        });
      });
  };

  /*---------------------------------------------*
  |                      Effects                 |
  *----------------------------------------------*/
  useEffect(() => {
    if (completeProcess) {
      setIsLoading(false);
      setCompleteProcess(false);
      CkMessage({type: "success", text: "Diagnóstico enviado correctamente."});
    }
  }, [completeProcess]);

  useEffect(() => {
    quotationConfirmation && appointmentId && getAppointmentTracking();
  }, [quotationConfirmation, appointmentId]);

  useEffect(() => {
    if (isLoading) setIsEditable(false);
    if (isLoading === false && quotationStatus !== "APPROVED")
      setIsEditable(true);
  }, [isLoading]);

  useEffect(() => {
    if (quotationItems) {
      const validate = validateQuotationItems(quotationItems);
      setIsValid(validate.isValid);
    }
  }, [quotationItems]);

  /*---------------------------------------------*
  |                   Components                 |
  *----------------------------------------------*/
  const CloseButton = () => (
    <>
      <span className="desktop-close-button">
        <CloseOutlined />
      </span>
      <span className="mobile-close-button">
        <LeftOutlined /> Regresar
      </span>
    </>
  );

  if (quotationConfirmation === false) return null;

  const onClose = () => {
    if (quotationStatus === "APPROVED") {
      setQuotationConfirmation(false);
    } else {
      setConfirmCloseModal(true);
    }
  };

  return (
    <Drawer
      className="quotation-form-drawer"
      open={quotationConfirmation}
      onClose={onClose}
      closeIcon={null}
      zIndex={5000}
      destroyOnClose
      title={null}
    >
      {appointmentTracking === undefined && (
        <Spin className="saving-spinner filled-background" size="large" />
      )}

      {appointmentTracking && (
        <>
          {isLoading && <Spin className="saving-spinner" size="large" />}
          <QuotationHeader
            appointmentTracking={appointmentTracking}
            appointmentInfo={appointmentInfo}
            hours={hours}
            appointmentId={appointmentId}
            workshopId={workshopId}
            currentDate={currentDate}
            SetCurrentDate={SetCurrentDate}
            currentTime={currentTime}
            SetCurrentTime={SetCurrentTime}
            isEditable={isEditable}
            onClose={onClose}
          />

          <QuotationForms
            quotationItems={quotationItems}
            setQuotationItems={setQuotationItems}
            isEditable={isEditable}
          />

          <QuotationFooter
            appointmentInfo={appointmentInfo}
            quotationStatus={quotationStatus}
            updateQuotationStatus={updateQuotationStatus}
            requestApproval={requestApproval}
            sendReminder={sendReminder}
            isValid={isValid}
          />

          <QuotationApprovalModal
            showQuotationApprovalModal={showQuotationApprovalModal}
            setShowQuotationApprovalModal={setShowQuotationApprovalModal}
            updateQuotationStatus={updateQuotationStatus}
            appointmentId={appointmentId}
          />

          <ConfirmationModal
            zIndex={5001}
            visible={confirmCloseModal}
            onOk={() => {
              setQuotationConfirmation(false);
              setConfirmCloseModal(false);
            }}
            onCancel={() => setConfirmCloseModal(false)}
            title="Advertencia"
            message="Los cambios realizados no serán guardados. ¿Desea proceder?"
            icon={<CalendarDeleteIcon />}
          />
        </>
      )}
    </Drawer>
  );
};

export default memo(QuotationFormModal);
