import React, { memo } from "react";
import { CkModal } from "../../../CkUI";

interface IProps {
  title: string;
  message: string;
  onConfirm: Function;
  onClose: Function;
  visible: boolean;
  okText?: string;
  cancelText?: string;
  showcloseIcon?: boolean;
  type?: "delete" | "default";
}

const ConfirmationMessage: React.FC<IProps> = ({
  title,
  message,
  onConfirm,
  visible,
  onClose,
  okText,
  cancelText,
  showcloseIcon,
  type,
}) => {
  return (
    <CkModal
      zIndex={2600}
      open={visible}
      title={title}
      onCancel={() => onClose()}
      primaryAction={{
        label: okText || "Aceptar",
        onClick: () => onConfirm(),
      }}
      secondaryAction={{
        label: cancelText || "Cancelar",
        onClick: () => onClose(),
      }}
      // closable={showcloseIcon != undefined ? showcloseIcon : true}
      type={type || "default"}
    >
      <p
        style={{
          fontSize: "16px",
          textAlign: "center",
          fontFamily: "Emprint-Regular",
        }}
      >
        {message}
      </p>
    </CkModal>
  );
};

export default memo(ConfirmationMessage);
