import React, { FC, memo } from 'react';
import {
  Button
} from 'antd';
import { Appointment } from "shared";
import { TQuotationStatus } from '../';
import "./styles.css";

interface IProps {
  appointmentInfo: Appointment;
  quotationStatus: TQuotationStatus;
  updateQuotationStatus: (string: TQuotationStatus | "FORCE") => void;
  requestApproval: Function;
  sendReminder: Function;
  isValid: boolean;
}
const QuotationFooter: FC<IProps> = ({
  appointmentInfo,
  quotationStatus,
  updateQuotationStatus,
  requestApproval,
  sendReminder,
  isValid
}) => {

  /**
   * if quotation is approved, we don't need to show anything.
   */
  // if (quotationStatus === "APPROVED") return null;

  return (
    <section className="quotation-footer">
      {quotationStatus === "DRAFT" &&
        <>
          <Button
            disabled={isValid === false}
            onClick={() => {updateQuotationStatus && updateQuotationStatus("WAITING_APPROVAL")}}
            className="--custom save-button"
          >
            Enviar esta cotización al cliente
          </Button>
          <Button
            onClick={() => updateQuotationStatus && updateQuotationStatus("DRAFT")}
            className="--custom send-button"
            type="primary"
            ghost
          >
            Guardar
          </Button>
        </>}

      {quotationStatus === "WAITING_APPROVAL" &&
        <>
          <Button 
            disabled={isValid === false}
            className="--custom save-button"
            onClick={() => {updateQuotationStatus && updateQuotationStatus("WAITING_APPROVAL")}}
          >
            Volver a cotizar {isValid}
          </Button>
          <Button
            disabled={isValid === false}
            onClick={() => requestApproval && requestApproval()}
            className="--custom approve-button"
            type="primary"
            ghost
          >
            El cliente aprobó por otro medio*
          </Button>
          {/* 
          <Button 
            onClick={() => sendReminder && sendReminder()}
            className="--custom --underlined reminder-button"
            type="link"
          >
            Enviar recordatorio al cliente
          </Button>
          */}
          <p className="notice-text">
            *Al seleccionar esta opción, confirma que el cliente aceptó por otro medio 
            (llamada telefónica, mensaje de texto, etc.) los servicios y precios presentados en esta pantalla.
          </p>
        </>}

      {quotationStatus === "APPROVED" && appointmentInfo && appointmentInfo?.statusCode === "DIAG" &&
        <Button
          onClick={() => updateQuotationStatus && updateQuotationStatus("FORCE")}
          className="--custom send-button"
          type="primary"
          ghost
        >
          Guardar
        </Button>}
    </section>
  )
}

export default memo(QuotationFooter);
