import React, { FC, memo } from "react";
import { Image, Button } from "antd";
import { useSelector } from "react-redux";

import { getSasToken } from "../../../../../../../stores";
import { TrashIcon } from "../../../../../../../assets/SvgIcons";
import { ISelectedFile } from "..";

import "./styles.css";

interface IProps {
  readOnly: boolean;
  file: ISelectedFile;
  setFile: Function;
}
const CustomAttachmentPreview: FC<IProps> = ({
  file,
  setFile,
  readOnly
} ) => {
  const sasToken = useSelector(getSasToken);
  
  const IsImage = (url: string) => {
    const fileTypes: any[] = ["png", "jpg", "jpeg"];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const FilePreview: FC<{
    readOnly?: boolean;
    file: ISelectedFile;
    fileType: "image";
  }> = ({ readOnly, file, fileType }) =>  (
    <figure className={`file-preview ${fileType}`}>
      {fileType === "image" && 
        <Image
          src={readOnly && file.photoUrl ? `${file.photoUrl}?${sasToken}` : file.preview}
          preview={false}
        />}
    </figure>);
  
  return (
    <div className="custom-attachment-preview">
      <div className="file-preview-label">
        <p>{file.name}</p>
        <Button className={"--custom"} icon={<TrashIcon />} type="ghost" onClick={() => setFile(undefined)}/>
      </div>
      <div className="preview-container">
        {IsImage(readOnly === true &&  file.photoUrl ? file.photoUrl : file.name) && 
          <FilePreview
            readOnly={readOnly}
            file={file}
            fileType="image"
          />}
      </div>
    </div>
    
  );
};

export default memo(CustomAttachmentPreview);
